import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import type { TOnChange } from '@frontend/pole-ui/lib/components/DatePicker'
import { DatePicker } from '@frontend/pole-ui/lib/components/DatePicker'
import { DATE_WITH_TIME_FORMAT } from '@/components/ui/fields/DatePicker/constants'
import type { TDatePickerViewProps } from './interfaces'
import './styles.scss'

const DatePickerFieldView: React.FC<TDatePickerViewProps> = props => {
  const { formatMessage } = useIntl()
  const { placeholder, input, placeholderId, onSelect, errorValues, pickerProps: initialPickerProps, ...otherProps } = props
  const placeholderResult = placeholderId ? formatMessage({ id: placeholderId }) : placeholder
  const { error, data } = otherProps.meta

  const onPickerChange: TOnChange = undatedValue => {
    onSelect?.(undatedValue)
    input.onChange(undatedValue)
  }

  const pickerProps = {
    ...initialPickerProps,
    format: props.withTimePicker ? DATE_WITH_TIME_FORMAT : undefined,
  }

  return (
    <DatePicker
      {...otherProps}
      onFocus={input.onFocus}
      onChange={onPickerChange}
      onBlur={input.onBlur}
      value={input.value}
      label={placeholderResult}
      errorText={<FormattedMessage id={`field.error.${error || data?.warning}`} values={errorValues} />}
      pickerProps={pickerProps}
    />
  )
}

export default DatePickerFieldView
