import React from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import type { TMultiSelectValueType } from '@frontend/pole-ui/lib/components/MultiSelect'
import { MultiSelect } from '@frontend/pole-ui/lib/components/MultiSelect'
import type { FieldValidator } from 'final-form'
import { getValidateFunction } from '@/utils/validators'
import { ELoanPurposeFieldName, LOAN_PURPOSE_OPTIONS } from './constants'
import { noPurposeValidator } from './helpers'
import { messages } from './messages'
import type { ILoanPurposeSelectProps } from './types'

export const LoanPurposeSelect: React.FC<ILoanPurposeSelectProps> = props => {
  const { className, errorClassName, isRequired = false } = props

  const validate = getValidateFunction(() => {
    const validators: FieldValidator<TMultiSelectValueType[]>[] = []

    if (isRequired) {
      validators.push(noPurposeValidator)
    }

    return validators
  })

  return (
    <Field name={ELoanPurposeFieldName.CHECK_PICKER} validate={validate}>
      {fieldProps => {
        const errorText =
          fieldProps.meta.error && fieldProps.meta.touched ? <FormattedMessage id={`field.error.${fieldProps.meta.error}`} /> : null

        return (
          <MultiSelect
            {...fieldProps.input}
            className={className}
            errorClassName={errorClassName}
            value={fieldProps.input.value}
            error={errorText}
            options={LOAN_PURPOSE_OPTIONS}
            placeholder={messages.multiSelect.placeholder}
            onChange={fieldProps.input.onChange}
            isRequired={isRequired}
          />
        )
      }}
    </Field>
  )
}
