import { EAgroservicesTypes } from '@/components/resolvers/AgroServices/enums'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { EAgroservicesRoute, EPriceUom } from '@/types'
import { ArgoservicesClickGtmEventPayload } from './gtmConstants'
import type { IAgroService } from './interfaces'

export const AGROSERVICES_MOBILE: IAgroService[] = [
  {
    productId: 'efisZsn',
    url: EAgroservicesRoute.efisZsn,
    logo: 'efisZsn',
    isHideDescriptionText: true,
  },
  {
    productId: 'agrocalculator',
    url: EAgroservicesRoute.agrocalc,
    logo: 'phosagroWhite',
  },
  {
    productId: 'seedingRateCalc',
    url: EAgroservicesRoute.seedingRateCalc,
  },
  {
    productId: 'consulting',
    url: EAgroservicesRoute.agroconsulting,
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.blockAgronomicSupport),
  },
  {
    productId: 'youDo',
    url: EAgroservicesRoute.youDo,
    blockType: EAgroservicesTypes.WIDE,
    logo: 'youDo',
    isHideDescriptionText: true,
    initialPrice: 'от 2%',
    paymentDescription: '/ за активного исполнителя',
    price: {
      value: '1,5% + 100',
      uom: EPriceUom.RUB,
    },
  },
  {
    productId: 'soil_landscape_survey',
    url: EAgroservicesRoute.farmlandCropValuation,
    logo: 'argonoutWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.farmlandСropValuation),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.paymentPeriod.soil_landscape_survey',
  },
  {
    productId: 'faq',
    url: '/faq',
    blockType: EAgroservicesTypes.SMALL,
  },
  {
    productId: 'ef_plus',
    url: EAgroservicesRoute.polePlus,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.polePlus),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },

  // TODO в задаче DEV-2644 (https://ddinvest.atlassian.net/browse/DEV-2644) обновление - проект "Хомяки" поставили
  //  на холд, данный сервис временно скрываем
  // {
  //   productId: 'price-analytics',
  //   url: EAgroservicesRoute.analyticsPricesAgriculturalProducts,
  //   blockType: EAgroservicesTypes.WIDE,
  // },
  {
    productId: 'ef_scout',
    url: EAgroservicesRoute.poleScouting,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.poleScouting),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'ef_diff',
    url: EAgroservicesRoute.poleDiff,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.poleDiff),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'ef_expert',
    url: EAgroservicesRoute.poleExpert,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.poleExpert),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
]

export const AGROSERVICES_TABLET: IAgroService[] = [
  {
    productId: 'efisZsn',
    url: EAgroservicesRoute.efisZsn,
    blockType: EAgroservicesTypes.WIDE,
    logo: 'efisZsn',
    isHideDescriptionText: true,
  },
  {
    productId: 'ef_plus',
    url: EAgroservicesRoute.polePlus,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.polePlus),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'ef_scout',
    url: EAgroservicesRoute.poleScouting,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.poleScouting),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'youDo',
    url: EAgroservicesRoute.youDo,
    blockType: EAgroservicesTypes.WIDE,
    logo: 'youDo',
    isHideDescriptionText: true,
    initialPrice: 'от 2%',
    paymentDescription: '/ за активного исполнителя',
    price: {
      value: '1,5% + 100',
      uom: EPriceUom.RUB,
    },
  },
  // TODO в задаче DEV-2644 (https://ddinvest.atlassian.net/browse/DEV-2644) обновление - проект "Хомяки" поставили
  //  на холд, данный сервис временно скрываем
  // {
  //   productId: 'price-analytics',
  //   url: EAgroservicesRoute.analyticsPricesAgriculturalProducts,
  //   blockType: EAgroservicesTypes.WIDE,
  // },
  {
    productId: 'soil_landscape_survey',
    url: EAgroservicesRoute.farmlandCropValuation,
    logo: 'argonoutWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.farmlandСropValuation),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.paymentPeriod.soil_landscape_survey',
  },
  {
    productId: 'faq',
    url: '/faq',
    blockType: EAgroservicesTypes.SMALL,
  },
  {
    productId: 'ef_diff',
    url: EAgroservicesRoute.poleDiff,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.poleDiff),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'ef_expert',
    url: EAgroservicesRoute.poleExpert,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.poleExpert),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'agrocalculator',
    url: EAgroservicesRoute.agrocalc,
    logo: 'phosagroWhite',
  },
  {
    productId: 'seedingRateCalc',
    url: EAgroservicesRoute.seedingRateCalc,
  },
  {
    productId: 'consulting',
    url: EAgroservicesRoute.agroconsulting,
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.blockAgronomicSupport),
  },
]

export const AGROSERVICES_DESKTOP: IAgroService[] = [
  {
    productId: 'efisZsn',
    url: EAgroservicesRoute.efisZsn,
    blockType: EAgroservicesTypes.WIDE,
    logo: 'efisZsn',
    isHideDescriptionText: true,
  },
  {
    productId: 'ef_plus',
    url: EAgroservicesRoute.polePlus,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.polePlus),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'ef_scout',
    url: EAgroservicesRoute.poleScouting,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.poleScouting),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'ef_expert',
    url: EAgroservicesRoute.poleExpert,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.poleExpert),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'youDo',
    url: EAgroservicesRoute.youDo,
    blockType: EAgroservicesTypes.WIDE,
    logo: 'youDo',
    isHideDescriptionText: true,
    initialPrice: 'от 2%',
    paymentDescription: '/ за активного исполнителя',
    price: {
      value: '1,5% + 100',
      uom: EPriceUom.RUB,
    },
  },
  // TODO в задаче DEV-2644 (https://ddinvest.atlassian.net/browse/DEV-2644) обновление - проект "Хомяки" поставили
  //  на холд, данный сервис временно скрываем
  // {
  //   productId: 'price-analytics',
  //   url: EAgroservicesRoute.analyticsPricesAgriculturalProducts,
  //   blockType: EAgroservicesTypes.WIDE,
  // },
  {
    productId: 'faq',
    url: '/faq',
    blockType: EAgroservicesTypes.SMALL,
  },
  {
    productId: 'ef_diff',
    url: EAgroservicesRoute.poleDiff,
    logo: 'exactFarmingWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.poleDiff),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.perYear',
  },
  {
    productId: 'soil_landscape_survey',
    url: EAgroservicesRoute.farmlandCropValuation,
    logo: 'argonoutWhite',
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.farmlandСropValuation),
    blockType: EAgroservicesTypes.SMALL,
    paymentDescription: 'agroServicesBlock.paymentPeriod.soil_landscape_survey',
  },
  {
    productId: 'agrocalculator',
    url: EAgroservicesRoute.agrocalc,
    logo: 'phosagroWhite',
  },
  {
    productId: 'seedingRateCalc',
    url: EAgroservicesRoute.seedingRateCalc,
  },
  {
    productId: 'consulting',
    url: EAgroservicesRoute.agroconsulting,
    onClick: () => pushFormattedGtmEvent(ArgoservicesClickGtmEventPayload.blockAgronomicSupport),
  },
]
