import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import InjectHtml from '../InjectHtml'
import type { IHeadingProps } from './interfaces'

const TableHeading: React.FC<IHeadingProps> = ({ titleId, className, textValues }) => (
  <div className={classNames('row row-align_center color_pale-black', className)}>
    <InjectHtml textId={titleId} textValues={textValues} />
    <span className="space-holder-left8 row hidden">
      <Icon name={EIconName.Filter} style={{ color: EColor.LIGHT_GRAY }} />
    </span>
  </div>
)

export default TableHeading
