import type { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import type { company, contacts, contactUs, main, social } from '@/components/composed/Footer/messages'

export enum EFooterSection {
  MAIN = 'main',
  COMPANY = 'company',
  SOCIAL = 'social',
  CONTACTS = 'contacts',
  CONTACT_US = 'contactUs',
}

export interface IFooterSectionProps {
  section: EFooterSection
  links: IFooterLink[]
  isHidden?: boolean
  isExpandable?: boolean
}

export type FooterLinkName = keyof typeof main | keyof typeof social | keyof typeof company | keyof typeof contacts | keyof typeof contactUs

export interface IFooterLink {
  name: FooterLinkName
  url?: string
  email?: string
  withLabel?: boolean
  icon?: EIconName
  isFilled?: boolean
  isUnderlined?: boolean
  isLarge?: boolean
  shouldOpenInNewWindow?: boolean
}

export enum EContactEmail {
  FEEDBACK = 'feedback@poleinvest.ru',
  PR = 'pr@poleinvest.ru',
  HR = 'hr@poleinvest.ru',
  MEDIA = 'media@poleinvest.ru',
}
