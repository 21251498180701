import { combineEpics, ofType } from 'redux-observable'
import { filter, mergeMap, race, skip, take, tap } from 'rxjs'
import type { IResponse } from '@/api/interfaces'
import { isErrorResponse } from '@/api/typeguards'
import type { NDataAction } from '@/logic/data/actions'
import { loadData, loadingDataFailed, loadingDataFinished } from '@/logic/data/actions'
import { isFailureDataLoadingPayload, isFinishedDataLoadingPayload } from '@/logic/data/typeguard'
import type { Epic, TActionStream } from '@/logic/interfaces'
import { pushProductsGtmEvent } from '@/logic/metrika/catalog/gtmEvents'
import { getMergedOfferWithSku } from '@/logic/metrika/catalog/helpers'
import type { TMergedOfferWithSku } from '@/logic/metrika/catalog/interfaces'
import { fetchAgriculturalProductCatalog } from '@/pages/AgriculturalProductCatalog/thunks'
import type { IAsyncThunkFetchProductCatalogResponse } from '@/pages/AgriculturalProductCatalog/types'
import type { ICatalogResponse } from '@/services/CatalogService'
import type { IAsyncThunkFetchProductCatalogPayload } from '@/types/Catalog'
import { getErrorTextFromResponseMeta } from '@/utils/metrika/helpers'

type TFetchProductsInPendingPayload = { type: string; meta: { arg: IAsyncThunkFetchProductCatalogPayload } }

const onSearchCatalogProductsDoPushGtmEvent: Epic<TFetchProductsInPendingPayload, () => TFetchProductsInPendingPayload> = action$ =>
  action$.pipe(
    filter(fetchAgriculturalProductCatalog.pending.match),
    mergeMap(action => {
      const currentSearchText = action.meta.arg.search?.searchText

      return race(
        (
          action$ as unknown as TActionStream<
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            IAsyncThunkFetchProductCatalogResponse | IResponse<any>,
            typeof fetchAgriculturalProductCatalog.fulfilled | typeof fetchAgriculturalProductCatalog.rejected
          >
        ).pipe(
          ofType(fetchAgriculturalProductCatalog.fulfilled.type, fetchAgriculturalProductCatalog.rejected.type),
          take(1),
          tap(({ type, payload }) => {
            pushProductsGtmEvent({
              products: isErrorResponse(payload)
                ? []
                : (payload?.products.reduce(
                    (result: TMergedOfferWithSku[], sku) => [...result, ...sku.offers.map(offer => getMergedOfferWithSku(offer, sku))],
                    [],
                  ) ?? []),
              eventAction: 'viewing',
              eventLabel: 'productInputs',
              eventStatus:
                fetchAgriculturalProductCatalog.rejected.type === type && isErrorResponse(payload)
                  ? (`err:${getErrorTextFromResponseMeta(payload.meta)}` as const)
                  : 'success',
              ecommerceType: 'impressions',
              blockName: currentSearchText ? 'blockSearchResult' : 'blockListingInputs',
              eventCategory: 'ecommerce',
              eventEcommerce: 'impressions',
              itemPosition: currentSearchText ? 'searchResultInputs' : 'listingInputs',
              eventNonInteraction: '1',
              categoryId: action.meta.arg.params?.categoryId,
            })
          }),
          skip(1),
        ),
      )
    }),
  )

const onSearchIndexPageProductsDoPushGtmEvent: Epic<NDataAction.ILoadData, typeof loadData> = action$ =>
  action$.pipe(
    filter(loadData.match),
    filter(({ payload }) => payload.name === 'indexAgriculturalProducts'),
    mergeMap(() =>
      race(
        (
          action$ as unknown as TActionStream<
            NDataAction.ILoadingDataFinished<ICatalogResponse> | NDataAction.ILoadingDataFailed,
            typeof loadingDataFinished | typeof loadingDataFailed
          >
        ).pipe(
          ofType(loadingDataFinished.type, loadingDataFailed.type),
          take(1),
          tap(({ type, payload }) => {
            pushProductsGtmEvent({
              pagePart: 'content',
              blockName: 'blockHomeInputs',
              eventCategory: 'ecommerce',
              eventLabel: 'productInputs',
              eventStatus:
                loadingDataFailed.type === type && isFailureDataLoadingPayload(payload)
                  ? (`err:${getErrorTextFromResponseMeta(payload.meta)}` as const)
                  : 'success',
              eventEcommerce: 'impressions',
              ecommerceType: 'impressions',
              eventNonInteraction: '1',
              itemPosition: 'homeListingInputs',
              products: isFinishedDataLoadingPayload(payload)
                ? payload.data.products?.reduce(
                    (result: TMergedOfferWithSku[], sku) => [...result, ...sku.offers.map(offer => getMergedOfferWithSku(offer, sku))],
                    [],
                  ) || []
                : [],
              eventAction: 'viewing',
            })
          }),
          skip(1),
        ),
      ),
    ),
  )
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(onSearchCatalogProductsDoPushGtmEvent, onSearchIndexPageProductsDoPushGtmEvent)
