/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RegionData } from './RegionData';

export type MainPageBannerData = {
    url?: string;
    regions: Array<RegionData>;
    webImage: string;
    mobileImage: string;
    specialAction: MainPageBannerData.specialAction;
};

export namespace MainPageBannerData {

    export enum specialAction {
        REGISTRATION_FORM = 'REGISTRATION_FORM',
        URL = 'URL',
    }


}

