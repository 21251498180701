import URI from 'urijs'
import { requestData } from '@/api/request'
import { ECatalogApiUrl } from '@/api/urls'
import type { RootState } from '@/redux/interfaces'
import type { ICatalogResponse, TFetchCatalogRequestParams } from './interfaces'

export class CatalogService {
  fetchCatalog = (params: TFetchCatalogRequestParams, state: RootState, options?: { apiUrl?: string; signal?: AbortSignal }) => {
    const { apiUrl = '', signal } = options ?? {}

    // здесь нужен `.escapeQuerySpace(false)`, потому что иначе пробелы заменяются на плюсы
    // и бэк может получить неправильное значение (но не проверялось)
    const url = new URI(`${apiUrl}${ECatalogApiUrl.catalog}`).escapeQuerySpace(false).addQuery(params).toString()
    // const url = getUrlWithQuery(`${apiUrl}${ECatalogApiUrl.catalog}`, params)

    return requestData<ICatalogResponse>({
      url,
      method: 'GET',
      signal,
      state,
    })
  }
}
