import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import type { ProduceAgrarianWarehouseDraftData } from '@/api/generated/base'
import type { IAutoSaveComparator } from '@/components/ui/fields/AutoSave/interfaces'
import { isLogisticFeaturesActive } from '@/constants/outputs'
import type { IUserGtmInfoPayload } from '@/interfaces'
import type { NHarvestSaleAction } from '@/logic/harvestSale/interfaces'
import type { THarvestVariantPage } from '@/logic/metrika/harvest/interfaces'
import { defaultProduceAgrarianWarehouseData } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/constants'
import type { IRegion } from '@/types'
import type { IIndicator, IQualityIndicator, ITemplate, TQualityName } from '@/types/HarvestSaleLots'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import type { IProduceAgrarianWarehouseData } from '@/types/HarvestSaleRequest/IProduceAgrarianWarehouseData'
import { getWorkingHoursOrMinutes } from '@/utils/helpers'
import type { IAgrarianWarehouses, IRequestHarvestSaleByTemplateFormValues, ISubmitButtonClickWithErrorValidation } from './interfaces'

export const getAvailableDeliveryBasisOptions = (template?: ITemplate): IDropdownOption<string>[] => {
  let deliveryBasesData = template?.buyer?.deliveryBasesData || []
  if (!deliveryBasesData.length) return []

  const withoutElevator = !template?.elevator?.id

  if (withoutElevator) {
    deliveryBasesData = deliveryBasesData.filter(
      basis =>
        basis.id !== EDeliveryBasisVariantId.elevator &&
        basis.id !== EDeliveryBasisVariantId.exw &&
        basis.id !== EDeliveryBasisVariantId.fob,
    )
  }

  return deliveryBasesData.map(({ id, name }) => ({
    value: id,
    label: name,
  }))
}

export const getAvailableDeliveryBasis = (template?: ITemplate): EDeliveryBasisVariantId => {
  const deliveryBasesData = getAvailableDeliveryBasisOptions(template)

  if (!deliveryBasesData.length) return EDeliveryBasisVariantId.pickup

  return deliveryBasesData[0]?.value as EDeliveryBasisVariantId
}

export const getPrice = (template?: ITemplate): number | undefined => {
  const currentBasisId = getAvailableDeliveryBasis(template)

  if (currentBasisId === EDeliveryBasisVariantId.exw) return template?.exw?.value
  if (currentBasisId === EDeliveryBasisVariantId.pickup) return template?.fca?.price?.value
  if (currentBasisId === EDeliveryBasisVariantId.elevator) return template?.price?.value
  if (currentBasisId === EDeliveryBasisVariantId.fob) return template?.fob?.value

  return undefined
}

export function transformIndicatorsToQualityIndicators(
  responseQualities: Partial<Record<TQualityName, IIndicator>>,
): IQualityIndicator<TQualityName>[] {
  if (!responseQualities) return []

  // using flatMap instead of map to drop values that doesn't pass check in the if block
  return (Object.keys(responseQualities) as TQualityName[]).flatMap(qualityKey => {
    const qualityObj = responseQualities[qualityKey]
    if (qualityObj) return [{ id: qualityKey, ...qualityObj }]

    return []
  })
}

export const transformFormValuesToRequestPayload = (
  formValues: IRequestHarvestSaleByTemplateFormValues,
  sourceOfOpening: THarvestVariantPage,
  userGtmInfo?: IUserGtmInfoPayload,
  userRegion?: IRegion,
  template?: ITemplate,
): NHarvestSaleAction.IRequestHarvestSalePayload => {
  const workingHours = formValues.produceAgrarianWarehouseData?.workingHours

  const distanceToScalesValue = formValues.produceAgrarianWarehouseData?.distanceToScales
    ? Number(formValues.produceAgrarianWarehouseData?.distanceToScales)
    : undefined

  const isWorkingHoursAvailable =
    workingHours?.start?.hours && workingHours?.start?.minutes && workingHours?.end?.hours && workingHours?.end?.minutes

  /** Приводим к формату 00:00 timeFrom и timeTill */
  const timeFrom = isWorkingHoursAvailable
    ? `${workingHours?.start?.hours?.length === 1 ? `0${workingHours?.start?.hours}` : workingHours?.start?.hours}:${
        workingHours?.start?.minutes === '0' ? '00' : workingHours?.start?.minutes
      }`
    : undefined

  const timeTill = isWorkingHoursAvailable
    ? `${workingHours?.end?.hours?.length === 1 ? `0${workingHours?.end?.hours}` : workingHours?.end?.hours}:${
        workingHours?.end?.minutes === '0' ? '00' : workingHours?.end?.minutes
      }`
    : undefined

  return {
    formValues: {
      ...formValues,
      agrarianWarehouseId:
        formValues?.deliveryOptions?.deliveryBasisId === EDeliveryBasisVariantId.pickup ? formValues?.agrarianWarehouseId : undefined,
      volume: formValues.volume,
      deliveryOptions:
        formValues?.deliveryOptions?.deliveryBasisId === EDeliveryBasisVariantId.pickup
          ? omit(formValues.deliveryOptions, ['elevatorId'])
          : formValues.deliveryOptions,
      produceAgrarianWarehouseData:
        formValues?.deliveryOptions?.deliveryBasisId === EDeliveryBasisVariantId.pickup && isLogisticFeaturesActive
          ? {
              ...omit(formValues.produceAgrarianWarehouseData, [
                'isScalesPlacedOutsideFarm',
                'isFarmWorkingAroundTheClock',
                'workingHours',
                'maxScalesLength',
                'minScalesLength',
                'id',
                'location',
                'agrarian',
                'scalesLength',
              ]),
              timeFrom,
              timeTill,
              distanceToScales: distanceToScalesValue,
              scalesLength: Number(formValues.produceAgrarianWarehouseData?.scalesLength),
            }
          : undefined,
    },
    template,
    sourceOfOpening,
    userGtmInfo,
    userRegion,
  }
}

export const shouldUpdateDraft: IAutoSaveComparator<IRequestHarvestSaleByTemplateFormValues> = (prevValues, values) => {
  if (!values.cultureId) return false

  return !isEqual(prevValues, values)
}

export const agrarianWarehouseInitialData = (value: IAgrarianWarehouses): IProduceAgrarianWarehouseData => ({
  ...value,
  workingDays: value?.workingDays?.id,
  loadingMethod: value?.loadingMethod?.id,
  minScalesLength: defaultProduceAgrarianWarehouseData.minScalesLength,
  maxScalesLength: defaultProduceAgrarianWarehouseData.maxScalesLength,
  isScalesPlacedOutsideFarm: Boolean(value?.distanceToScales),
  scalesLength: value?.scalesLength?.toString(),
  workingHours:
    value?.timeFrom && value?.timeTill
      ? {
          start: {
            hours: getWorkingHoursOrMinutes(value?.timeFrom?.substring(0, 2)),
            minutes: getWorkingHoursOrMinutes(value?.timeFrom?.substring(3)),
          },
          end: {
            hours: getWorkingHoursOrMinutes(value?.timeTill?.substring(0, 2)),
            minutes: getWorkingHoursOrMinutes(value?.timeTill?.substring(3)),
          },
        }
      : undefined,
  locationId: value?.location?.id,
})

export const agrarianWarehouseDraftData = (value: ProduceAgrarianWarehouseDraftData): IProduceAgrarianWarehouseData => ({
  ...value,
  workingDays: value?.workingDays,
  loadingMethod: value?.loadingMethod,
  minScalesLength: defaultProduceAgrarianWarehouseData.minScalesLength,
  maxScalesLength: defaultProduceAgrarianWarehouseData.maxScalesLength,
  isScalesPlacedOutsideFarm: Boolean(value?.distanceToScales),
  scalesLength: value?.scalesLength?.toString(),
  workingHours:
    value?.timeFrom && value?.timeTill
      ? {
          start: {
            hours: getWorkingHoursOrMinutes(value?.timeFrom?.substring(0, 2)),
            minutes: getWorkingHoursOrMinutes(value?.timeFrom?.substring(3)),
          },
          end: {
            hours: getWorkingHoursOrMinutes(value?.timeTill?.substring(0, 2)),
            minutes: getWorkingHoursOrMinutes(value?.timeTill?.substring(3)),
          },
        }
      : undefined,
})

export const onSubmitButtonClickWithErrorValidation = (args: ISubmitButtonClickWithErrorValidation) => {
  const { submit, formHasErrors, setErrorClassValue } = args

  submit()
  if (formHasErrors) setErrorClassValue('.input-wrapper_has-error')
}

export const getCultureFromUrl = (url: string) => {
  const culture = url
    .split('&')
    .find(el => el.includes('culture'))
    ?.split('=')[1]

  return culture
}
