import type { FaqArticleData, FaqCategoryData } from '@/api/generated/base'
import type { IBreadcrumb } from '@/components/ui/Breadcrumbs/Breadcrumb/interfaces'
import { isDesktop } from '@/logic/app/reducer'
import { hasParamInUrl } from '@/logic/data/helpers'
import type { TRequestParams } from '@/logic/data/interfaces'
import { getRequestData } from '@/logic/data/reducer'
import type { RootState } from '@/redux/interfaces'
import { breadcrumbs } from './constants'
import type { IFaqParams } from './types'

export const getArticleUrl = ({ categoryId, specializationId, questionId }: Required<Omit<IFaqParams, 'authorId'>>) =>
  `/faq/categories/${categoryId}/specialization/${specializationId}/question/${questionId}`

export const getArticleBreadcrumbs = (category?: FaqCategoryData | null, articleBreadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] => {
  if (!category) return articleBreadcrumbs

  const { parent: parentCategory, name, id } = category

  if (parentCategory) {
    // Сделано так, потому что первая категория - Раздел «Вопросы и ответы», которую не требуется отображать
    articleBreadcrumbs.unshift({
      caption: name,
      link: `/faq/categories/${parentCategory && parentCategory.parent ? `${parentCategory.id}/specialization/${id}` : id}`,
    })

    return getArticleBreadcrumbs(parentCategory, articleBreadcrumbs)
  }

  return articleBreadcrumbs
}

export const getParentCategories = (category?: FaqCategoryData, categories: string[] = []): string[] => {
  if (!category) return categories

  const { parent: parentCategory, name } = category

  if (parentCategory) {
    categories.unshift(name)

    return getParentCategories(parentCategory, categories)
  }

  return categories
}

export const getFaqBreadcrumbs = (state: RootState, params: TRequestParams): IBreadcrumb[] => {
  if (isDesktop()(state)) return breadcrumbs

  if (hasParamInUrl('questionId')({ params }))
    return [...breadcrumbs, ...getArticleBreadcrumbs(getRequestData<FaqArticleData>('faqArticle')(state).data?.category)]
  if (hasParamInUrl('specializationId')({ params }))
    return [...breadcrumbs, ...getArticleBreadcrumbs(getRequestData<FaqCategoryData>('faqSpecialization')(state).data?.parent)]

  return breadcrumbs
}
