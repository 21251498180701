import type { ResponseConfig } from '@/api/client/types'
import type { CreateFundingRequestData, FundingRequestData } from '@/api/generated/base'
import { isStatusOk } from '@/api/helpers'
import type { GetApprovedRegionsQueryResponse } from '@/api/kubik/monolith'
import { getApprovedRegions } from '@/api/kubik/monolith'
import { requestData } from '@/api/request'
import { ECommonApiUrl } from '@/api/urls'
import type { EFinancingProductId } from '@/types'

export default class FinancingService {
  create = async (body: CreateFundingRequestData, isLost = false) => {
    const fundingRequestApiUrl = isLost ? ECommonApiUrl.fundingRequestsLost : ECommonApiUrl.fundingRequests

    const response = await requestData<FundingRequestData[]>({
      url: fundingRequestApiUrl,
      method: 'POST',
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response
  }

  // На беке типизация не совпадает с реальным ответом. Есть обертка data, meta
  getAvailableRegions = async (productId: EFinancingProductId) =>
    getApprovedRegions({ productId }) as unknown as Promise<ResponseConfig<GetApprovedRegionsQueryResponse>>
}
