import React from 'react'
import { FaqAgroservicesBanner } from '@/components/composed'
import { ResponsiveView } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import { useParams } from '@/router/hooks'
import { FaqDesktop, FaqMobile } from './components'
import { FaqContext } from './constants'
import messages from './messages.json'
import type { IFaqParams } from './types'
import './styles.scss'

injectMessages(messages)

const QuestionsAndAnswers: React.FC = () => {
  const params = useParams<IFaqParams>()

  return (
    <FaqContext.Provider value={{ params }}>
      <ResponsiveView Desktop={FaqDesktop} Tablet={FaqMobile} Mobile={FaqMobile} />
      <FaqAgroservicesBanner className="faq-banner" />
    </FaqContext.Provider>
  )
}

export default QuestionsAndAnswers
