import type { PayloadAction } from '@reduxjs/toolkit'
import type { NDataAction } from '@/logic/data/actions'
import type { IFetchAgrarianOrderPayload } from '@/logic/order/interfaces'
import type { EProfileRequestType } from '@/logic/profile/constants'
import type { EOrderPaymentVariantType } from '@/types/Cart'
import type { EAgrarianOrderStatus } from '@/types/Order'
import type { TEntityId } from '@/types/TEntityId'
//eslint-disable-next-line max-len
import type { IEditingDisagreementProtocolModalFormValues } from '../../forms/DealAgreementForm/components/EditingDisagreementProtocolModal/interfaces'
import type { TDealAgreementOperationName } from '../../forms/DealAgreementForm/interfaces'
import type { IModalContentInjectedProps } from '../../Modal/interfaces'

export type TSaveDisagreementProtocol = (values: IEditingDisagreementProtocolModalFormValues) => void

export enum EDocumentType {
  OFFER = 'offer',
  ASSIGNMENT = 'assignment',
}

export interface IDealAgreementModalOptions {
  entityType: EProfileRequestType
  id: TEntityId
  version: string
  paymentType?: EOrderPaymentVariantType
  orderStatusId: EAgrarianOrderStatus
  documentType?: EDocumentType
}

export interface IDealAgreementModalProps extends IModalContentInjectedProps {
  options: IDealAgreementModalOptions
}

export interface IGetRequestUrlOptions {
  entityType: EProfileRequestType
  id: TEntityId
  version: string
  urlEnd?: string
  documentType?: EDocumentType
}

export interface IAcceptingDealAgreementBody {
  tokenId: string
  code: string
}

export declare namespace NDealAgreementAction {
  interface ISendSmsCodeForAcceptingAgreement {
    validTo?: string
    entityType: EProfileRequestType
    id: TEntityId
    version: string
    operationName: TDealAgreementOperationName
    documentType?: EDocumentType
  }

  interface IAcceptDealAgreement {
    code: string
    entityType: EProfileRequestType
    id: TEntityId
    version: string
    documentType?: EDocumentType
    orderStatusId?: EAgrarianOrderStatus
    paymentType?: EOrderPaymentVariantType
  }
}

export type TSetEntityAction = (id: TEntityId) => PayloadAction<IFetchAgrarianOrderPayload | NDataAction.ILoadData>
