import { EMAIL_RE, LAST_DOT_RE, LAST_ZEROS_RE, LEAD_ZEROS_RE, NON_NUMBER_SYMBOLS_RE, ONLY_DIGITS_RE } from '@/constants/regex'
import type { TTextInputInternalValidator } from './types'

export const isPhoneNumber: TTextInputInternalValidator<string> = value => {
  if (!value) return undefined

  return value.length === 11
}

export const isEmail: TTextInputInternalValidator<string> = value => {
  if (!value) return undefined

  return EMAIL_RE.test(value)
}

export const isDigits: TTextInputInternalValidator<string> = value => {
  if (!value) return undefined

  return ONLY_DIGITS_RE.test(value)
}

export const isNumber: TTextInputInternalValidator<string> = value => {
  if (!value) return undefined

  const stringValue = value.toString()

  if (!stringValue.trim()) return false

  const num = Number(stringValue)

  return !Number.isNaN(num)
}

export const isValidNumber: TTextInputInternalValidator<string> = value => {
  if (!isNumber(value)) return undefined

  /*
    Number('876873343434.23422') => 876873343434.2343 // !
    Number(' 87687334343411.23422') => 87687334343411.23 // !
    Number('1234.') => 1234
    Number('1234.00') => 1234
    Number('967.80') => 967.8
    Number('  -.1233') => -0.1233
    Number('000005.5000') => 5.5
    Number(' -00.0040') => -0.004
    Number('-0.') => -0
    Number('0.') => 0
    Number('-.0') => -0
    Number('.0') => 0
  */
  return Number(value).toString() === normalizeValue(value)
}

function normalizeValue(value: string): string {
  let val = value.replace(NON_NUMBER_SYMBOLS_RE, '')

  const isNegative = val[0] === '-'

  val = isNegative ? val.slice(1) : val

  val = val.replace(LEAD_ZEROS_RE, '')

  const isFloatNumber = val.indexOf('.') >= 0

  val = isFloatNumber ? val.replace(LAST_ZEROS_RE, '') : val

  val = val.replace(LAST_DOT_RE, '')
  val = val.indexOf('.') === 0 ? `0${val}` : val
  val = !val ? '0' : val

  return isNegative && val !== '0' ? `-${val}` : val
}

export const isIntegerNumber: TTextInputInternalValidator<string> = value => {
  if (!isValidNumber(value)) return undefined

  return Number.isInteger(Number(value))
}

export const isNumberLessThen =
  (max: number, include?: boolean): TTextInputInternalValidator<string> =>
  value => {
    if (!isValidNumber(value)) return undefined

    const num = Number(value)

    return include ? num <= max : num < max
  }

export const isNumberGreaterThen =
  (min: number, include?: boolean): TTextInputInternalValidator<string> =>
  value => {
    if (!isValidNumber(value)) return undefined

    const num = Number(value)

    return include ? num >= min : num > min
  }

export function isValidFloatPrecision(digitsAfterDot: number): TTextInputInternalValidator<string> {
  return value => {
    if (!isValidNumber(value)) return undefined

    const numValue = Number(value)

    if (Number.isInteger(numValue)) {
      return true
    }

    const valueAfterDots = numValue.toString().split('.')[1]

    return (valueAfterDots?.length ?? 0) <= digitsAfterDot
  }
}
