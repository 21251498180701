import type { IAgriculturalProductTreeCategory } from '@/types/Catalog'

export const getFlattenedCategoriesFromTree = (
  categories?: IAgriculturalProductTreeCategory[] | IAgriculturalProductTreeCategory,
): IAgriculturalProductTreeCategory[] => {
  if (!categories) return []

  const getFlattenCategory = (category: IAgriculturalProductTreeCategory) =>
    [category, ...(category.children || []).map(childCategory => getFlattenedCategoriesFromTree(childCategory))].flat()

  return (Array.isArray(categories) ? categories.map(category => getFlattenCategory(category)) : getFlattenCategory(categories)).flat()
}
