import type { TMessages } from '@/types/TMessages'

export const messages = {
  requiredText: {
    part1: 'Я соглашаюсь с ',
    part2: ' обработки персональных данных, присоединяюсь к ',
    part3: ' использования Маркетплейса, включая ',
  },
  noRequiredText: {
    part1: 'Я соглашаюсь получать сообщения рекламного характера в соответствии с ',
    part2: ' использования Маркетплейса',
  },
  rulesLink: {
    part1: 'Правилам ',
    part2: 'Правилами ',
    policy: 'Политикой ',
  },
  agree: {
    link: 'ПЭП и УКЭП',
  },
  errors: {
    required: 'Необходимо заполнить',
  },
} satisfies TMessages
