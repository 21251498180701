import type { Dictionary } from '@reduxjs/toolkit'
import { Promise } from 'core-js'
import createDecorator from 'final-form-focus'
import Cookies from 'js-cookie'
import decodeJwtToken from 'jwt-decode'
import camelCase from 'lodash/camelCase'
import compact from 'lodash/compact'
import flow from 'lodash/flow'
import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import memoize from 'lodash/memoize'
import reduce from 'lodash/reduce'
import type { IJwtPayload } from '@/logic/auth/interfaces'
import { history } from '../browserHistory'
import { ExecutionEnvironment } from './ExecutionEnvironment'

const limitationOfLiabilityCookieName = 'isLimitationOfLiabilityAccepted'

export const setLimitationOfLiabilityAcceptedState = () => Cookies.set(limitationOfLiabilityCookieName, 'true', { expires: 365 })

export const isLimitationOfLiabilityAccepted = () => Cookies.get(limitationOfLiabilityCookieName)

export const decodeJwt = (jwt: string) => decodeJwtToken<IJwtPayload>(jwt)

export const timeout = <T>(ms: number, payload: T): Promise<T> => new Promise(resolve => setTimeout(() => resolve(payload), ms))

export interface IGetTokenInfo {
  jwt: string
  payload: IJwtPayload
}

export const getTokenInfo = (jwt: string): IGetTokenInfo | null => {
  try {
    const payload = decodeJwt(jwt)

    return { jwt, payload }
  } catch (e) {
    return null
  }
}

export const getJwtExpirationTime = (jwt: string) => {
  const token = getTokenInfo(jwt)
  if (!token) return 0

  return get(token, 'payload.exp', 0) * 1000
}

export function roundFloat(value: number): number {
  return Math.round(value * 100) / 100
}

export function sanitizePhoneNumber(phone: string): string {
  if (!phone) return ''
  const plusExists = phone[0] === '+'

  return phone.slice(plusExists ? 1 : 0).slice(0, 11)
}

export function unmask(phone: string): string {
  return phone.replace(/[+ ()-]/g, '')
}

/**
 * Форматирует номер типа '79621133231' или '+79621133231' в '+79 621-133-231'
 * @param {string} phone
 */
export function formatPhone(phone?: string, mask: string = '+7 (999) 999-99-99') {
  if (!phone) return ''

  let i = 0
  const phoneValue = sanitizePhoneNumber(phone[0] === '7' ? phone.slice(1) : phone)

  // eslint-disable-next-line no-plusplus
  return mask.replace(/9/g, () => phoneValue[i++] || '')
}

export const getRandomNumber = (max: number, min: number = 0): number => {
  if (max < min) {
    ;[min, max] = [max, min]
  }

  return Math.floor(Math.random() * (max - min)) + min
}

// TODO why 7?
export const getRandomId = (length: number = 7): string => {
  if (length <= 0) {
    throw new Error('Length must be a positive number')
  }

  return Math.random().toString(36).substring(length)
}

export const getRandomElement = <T>(array: T[]): T => {
  if (array.length === 0) {
    throw new Error('Array must not be empty')
  }

  const index = getRandomNumber(0, array.length - 1)
  // we use type assertion here because we excluded out of bounds error by checks
  const element = array[index] as T

  return element
}

export const createItems = <T>(quantity: number, generator: (index: number) => T): T[] =>
  Array(quantity)
    .fill(undefined)
    .map((_, index) => generator(index))

export const generateRandomTestData = <T>(generator: (index: number) => T, max: number = 10, min = 1): T[] =>
  createItems(getRandomNumber(max, min), generator)

// https://gist.github.com/diolavr/d2d50686cb5a472f5696
// https://ru.wikipedia.org/wiki/%D0%A2%D1%80%D0%B0%D0%BD%D1%81%D0%BB%D0%B8%D1%82
const translitedRussionChars: Dictionary<string> = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'yo',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'iy',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'kh',
  ц: 'c',
  ч: 'ch',
  ш: 'sh',
  щ: 'shch',
  ы: 'y',
  э: 'e',
  ю: 'u',
  я: 'ya',
  '+': 'Plus',
}

export const translit = (text: string = ''): string => {
  let translitedString = ''
  const fixedText = text.replace(/[ъь]+/gi, '')

  for (let i = 0; i < fixedText.length; i += 1) {
    const char = fixedText[i]

    if (char) {
      const translitedChar = translitedRussionChars[char.toLowerCase()]
      if (!translitedChar) translitedString += char
      else translitedString += char.toUpperCase() === char ? translitedChar.toUpperCase() : translitedChar
    }
  }

  return translitedString
}

export const getTranslitedTextInCamelCase = flow(translit, camelCase)
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertFilterCheckboxesToQuery = (values: Record<string, any> = {}): Record<string, boolean | string[]> =>
  reduce(
    values,
    (filterValues, filterValue, filterName) => ({
      ...filterValues,
      [filterName]: compact(
        isPlainObject(filterValue)
          ? Object.keys(filterValue).reduce((result: string[], name) => (filterValue[name] ? [...result, name] : result), [])
          : filterValue,
      ),
    }),
    {},
  )

export const getElementOffsetFormPageTop = (element: Element | null): number => {
  const bodyTop = document.body.getBoundingClientRect().top
  const elementTop = element?.getBoundingClientRect().top || 0

  return elementTop - bodyTop
}

export const scrollTo = (selector: string, offset?: number, shouldPushToHistory = true, isPlacedTargetOnCenter = false): void => {
  const element = document?.querySelector(selector)
  if (shouldPushToHistory && /^#.+/.test(selector)) history.push({ hash: selector })
  if (typeof offset === 'number') window.scrollTo(0, offset)
  else if (isPlacedTargetOnCenter) element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  else element?.scrollIntoView({ behavior: 'smooth' })
}

export const memoizeObject = ExecutionEnvironment.isSsr ? <T>(item: T): T => item : memoize(<T>(item: T): T => item, JSON.stringify)

export const focusOnErrors = createDecorator

export const asyncScrollIntoView = (selector: string, offset: number = 0): Promise<void> => {
  const element = document.querySelector(selector)
  const rect = element?.getBoundingClientRect()
  const { bottom = 0, top = 0, height = 0 } = rect ?? {}
  const elementBottom = Math.floor(bottom + self.scrollY)
  const scrollTarget = top + self.scrollY - offset
  window.scroll(0, scrollTarget)

  const promise: Promise<void> = new Promise((resolve, reject) => {
    const failed = setTimeout(reject, 2000)

    const scrollHandler = () => {
      if (Math.floor(self.scrollY + height) === elementBottom) {
        window.removeEventListener('scroll', scrollHandler)
        clearTimeout(failed)
        resolve()
      }
    }

    if (self.scrollY === elementBottom) {
      clearTimeout(failed)
      resolve()
    } else {
      window.addEventListener('scroll', scrollHandler)
    }
  })

  return promise.catch(() => {})
}

export const normalizeDouble = (value: number) => parseFloat(value.toFixed(2))

export function pairwise<T>(arr: T[], func: (current: T, next: T) => void) {
  for (let i = 0; i < arr.length - 1; i += 1) {
    const current = arr[i]
    const next = arr[i + 1]

    if (current !== undefined && next !== undefined) {
      func(current, next)
    }
  }
}

export function getWorkingHoursOrMinutes(value: string): string {
  return value[0] === '0' ? value.slice(1, 2) : value
}

export function wordForm(num: number, titles: { once: string; twice: string; many: string }) {
  if (num % 10 === 1 && num % 100 !== 11) {
    return titles.once
  } else if (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)) {
    return titles.twice
  }

  return titles.many
}
