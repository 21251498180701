import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import URI from 'urijs'
import { useMakeRequest } from '@/api/hooks/useMakeRequest'
import type { IResponse } from '@/api/interfaces'
import { changePageNumberInQuery } from '@/components/ui/PaginationDeprecated/helpers'
import { useSorterButtons } from '@/components/ui/SorterButtons/useSorterButtons'
import { getPageNumberInQuery } from '@/logic/app/reducer'
import type { RootState } from '@/redux/interfaces'
import { getPaginationQuery } from './helpers'
import type { IPaginatedApiQuery, IUsePaginatedDataOptions, IUsePaginatedDataResponse } from './interfaces'

const mapState = (itemsPerPage?: number) => (state: RootState) => ({
  pageNumber: getPageNumberInQuery(state),
  ...getPaginationQuery({ itemsPerPage })(state),
})

export const usePaginatedApiData = <TSorterId, RespondedData>(
  options: IUsePaginatedDataOptions<TSorterId>,
): IUsePaginatedDataResponse<TSorterId, RespondedData> => {
  const {
    itemsPerPage,
    apiUrl,
    defaultSorterId,
    withPagination = true,
    sorterAttribute = 'status',
    queryParams,
    method = 'GET',
    body = {},
    withoutSorterId,
  } = options
  const { pageNumber, limit, offset } = useSelector(mapState(itemsPerPage))
  const { currentSorterId, setSorterId } = useSorterButtons<TSorterId | undefined>(defaultSorterId)
  const [paginationKey, setPaginationKey] = useState<number>(0)
  const [prevBody, setPrevBody] = useState('')
  const [forceUpdatingCount, setForceUpdatingCount] = useState<number>(0)
  const currentBodyStringify = JSON.stringify(body)

  const url = useMemo(() => {
    let query: IPaginatedApiQuery<TSorterId> = { ...queryParams }
    if (withPagination) query = { ...query, limit, offset }
    if (currentSorterId && !withoutSorterId) query[sorterAttribute] = currentSorterId

    const uri = new URI(apiUrl)

    Object.entries(query).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => uri.addQuery(key, item))
      } else {
        uri.addQuery(key, value)
      }
    })

    return uri.toString()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSorterId, pageNumber, limit, queryParams, withoutSorterId])

  const { makeRequest, ...otherRequestData } = useMakeRequest<IResponse<RespondedData>>(url, { method })

  const goToFirstPage = () => {
    changePageNumberInQuery(1)
    setPaginationKey(paginationKey + 1)
  }

  useEffect(
    () => {
      if (currentBodyStringify !== prevBody) {
        setPrevBody(currentBodyStringify)
        goToFirstPage()
      }

      return method !== 'GET' ? makeRequest(body) : makeRequest()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forceUpdatingCount, currentSorterId, pageNumber, limit, currentBodyStringify],
  )

  return {
    ...otherRequestData,
    currentSorterId,
    setSorterId,
    paginationKey,
    goToFirstPage,
    pageNumber,
    updateData: () => setForceUpdatingCount(prev => prev + 1),
    goToPreviousPage: () => {
      changePageNumberInQuery(pageNumber - 1)
      setPaginationKey(paginationKey - 1)
    },
    makeRequest,
    currentRequestUrl: url,
  }
}
