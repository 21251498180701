import type { FC } from 'react'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { openModal } from '@/components/composed/Modal/actions'
import { Button } from '@/components/ui'
import type { IChangeOrgButtonProps } from '@/components/ui/ChangeOrgButton/types'
import { getUser } from '@/logic/auth/reducer'
import { injectMessages } from '@/logic/translation/utils'
import type { RootState } from '@/redux/interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  user: getUser()(state),
})

const ChangeOrgButton: FC<IChangeOrgButtonProps> = props => {
  const { className, labelClassName, buttonClassName } = props
  const dispatch = useDispatch()
  const { user } = useSelector(mapState)
  const { organizationName, organizationHolding } = user || {}

  const handlerChangeActiveOrganisation = () => {
    dispatch(
      openModal({
        options: {
          dialogId: 'ChangeActiveOrganisationModal',
        },
      }),
    )
  }

  if (!organizationHolding) return null

  return (
    <div className={classNames('changeOrgButton', className)}>
      <span className={classNames('changeOrgButton__label', labelClassName)}>
        <FormattedMessage id="changeOrgButton.label" />
      </span>
      <Button isUnstyled className={classNames('changeOrgButton__button', buttonClassName)} onClick={handlerChangeActiveOrganisation}>
        <span className="changeOrgButton__organizationName">{organizationName}</span>
        <Icon name={EIconName.ShevronDown} size={EIconSize.XS} />
      </Button>
    </div>
  )
}

export default memo(ChangeOrgButton)
