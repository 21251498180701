import type { FC } from 'react'
import React, { memo } from 'react'
import { Logo } from '@/components/ui'
import { HeaderActions, RegionChooserBottom, ScrollableHeader } from './components'

const HeaderContent: FC = memo(() => (
  <div className="row_nowrap page-wrapper background_white headerContentMobile">
    <Logo url="/" className="header-logo" />
    <HeaderActions />
  </div>
))

const HeaderMobile: React.FC = () => (
  <>
    <div className="header-mobile-litter" />
    <ScrollableHeader>
      <HeaderContent />
    </ScrollableHeader>
    <RegionChooserBottom />
  </>
)

export default HeaderMobile
