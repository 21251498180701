import type { PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { combineEpics } from 'redux-observable'
import { of, throwError } from 'rxjs'
import { catchError, filter, map, mapTo, mergeMap, skipWhile, switchMap, tap } from 'rxjs/operators'
import { isStatusOk } from '@/api/helpers'
import request from '@/api/request'
import { EAuthApiUrl } from '@/api/urls'
import { closeModal, openModal } from '@/components/composed/Modal/actions'
import { clearData } from '@/logic/data/actions'
import { AuthCookiesService } from '@/services'
import { ECommonRoute, EUserPermission } from '@/types'
import { EInfoportalAdministrationRoute } from '@/types/Infoportal'
import { getTokenInfo } from '@/utils/helpers'
import { onAppMountWithEpics } from '../app/actions'
import type { Epic } from '../interfaces'
import { clearUserProfile, fetchUserProfile } from '../profile/actions'
import { getMessage } from '../translation/selectors'
import type { NAuthAction } from './actions'
import {
  authImpersonationRequest,
  authImpersonationRequestFailed,
  authImpersonationRequestFinished,
  authRequestFailed,
  authRequestFinished,
  authRequestStarted,
  clearSession,
  logoutImpersonationUser,
  logoutUser,
  registrationRequestFailed,
  registrationRequestSuccess,
  sendCodeBySms,
  sendingSmsRequestFailed,
  sendingSmsRequestFinished,
  sendRegistrationRequest,
  setJwt,
  setSendingCodeState,
  startRefreshJwt,
  submitLoginForm,
} from './actions'
import { EShouldRegisterUserMetaStatus, timeToAllowSendingCode } from './constants'
import { getErrorMessageId, hasPermission } from './helpers'
import type { IJwtPayload, IRegistrationResponse, ISendCodeBySmsResponse, ISubmitLoginResponse } from './interfaces'
import { sendConfirmNumberEvent, sendRegistrationErrorEvent, sendRegistrationSuccessfullEvent, sendRegistrationWrongPhone } from './metrika'
import { getAuthTokenId, isAuthenticated, isImpersonationUser } from './reducer'

const onAppMountDoSetJwtToken: Epic<undefined, typeof onAppMountWithEpics> = action$ =>
  action$.pipe(
    filter(onAppMountWithEpics.match),
    filter(() => Boolean(getTokenInfo(AuthCookiesService.getJwt()))),
    map(() => setJwt({ jwt: AuthCookiesService.getJwt() })),
  )

const onSubmitLoginFormDoMakeAuthRequest: Epic<NAuthAction.ISubmitLoginFormPayload, typeof submitLoginForm> = (action$, state$) =>
  action$.pipe(
    filter(submitLoginForm.match),
    mergeMap(({ payload }) => {
      const { phone, code } = payload

      return request<ISubmitLoginResponse>({
        url: EAuthApiUrl.getToken,
        body: {
          phone,
          code,
          tokenId: getAuthTokenId()(state$.value),
        },
        method: 'POST',
      }).pipe(
        mergeMap(response => {
          if (!isStatusOk(response)) return throwError(response)

          if (state$.value.auth.shouldRegisterUser) {
            sendRegistrationSuccessfullEvent(state$.value)
          } else {
            sendConfirmNumberEvent(true)
          }

          const { token: jwt } = response.data
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          const actions: PayloadAction<any>[] = [setJwt({ jwt })]
          if (state$.value.auth.shouldRegisterUser) actions.push(openModal({ options: { dialogId: 'SuccessfulRegistrationModal' } }))
          const { permissions, organizationHolding } = getTokenInfo(jwt)?.payload as IJwtPayload

          if (organizationHolding) {
            actions.push(openModal({ options: { dialogId: 'ChangeActiveOrganisationModal' } }))
          } else {
            actions.push(closeModal())
          }

          if (hasPermission(permissions, EUserPermission.ADMIN)) actions.push(push(ECommonRoute.impersonation))
          if (hasPermission(permissions, EUserPermission.CONTENT_MANAGER)) actions.push(push(EInfoportalAdministrationRoute.PUBLISHED))

          AuthCookiesService.setJwt(jwt)

          return actions
        }),
        catchError(({ meta }) => {
          sendConfirmNumberEvent(false)

          return of(authRequestFailed({ errorStatus: meta.status }))
        }),
      )
    }),
  )

const onRefreshJwtDoMakeRequest: Epic<undefined, typeof startRefreshJwt> = action$ =>
  action$.pipe(
    filter(startRefreshJwt.match),
    mapTo(authRequestStarted()),
    mergeMap(() =>
      request<ISubmitLoginResponse>({
        method: 'PUT',
        url: EAuthApiUrl.refreshToken,
      }).pipe(
        map(({ data }) => setJwt({ jwt: data.token })),
        catchError(() => of(clearSession(), authRequestFinished())),
      ),
    ),
  )

const onLogoutClickedDoClearSession: Epic<NAuthAction.IAuthLogoutPayload, typeof logoutUser> = (action$, state$) =>
  action$.pipe(
    filter(logoutUser.match),
    filter(() => isAuthenticated()(state$.value)),
    mergeMap(({ payload }) => {
      const { redirectUrl } = payload

      if (isImpersonationUser()(state$.value)) return [logoutImpersonationUser()]

      return [
        authRequestStarted(),
        clearSession(),
        clearUserProfile(),
        clearData({ name: 'multiCart' }),
        clearData({ name: 'userOrganization' }),
        push(redirectUrl),
      ]
    }),
  )

const onClearSessionDoClearAuthCookies: Epic<undefined, typeof clearSession> = action$ =>
  action$.pipe(
    filter(clearSession.match),
    tap(() => {
      AuthCookiesService.clearRefreshToken()
      AuthCookiesService.clearJwt()
    }),
    skipWhile(() => true),
  )

const setSendingCodeStateActionCreator = (isSendingAgain: boolean | undefined) =>
  setSendingCodeState({ canSendCodeAgain: !isSendingAgain, timeUntilSendingCode: Date.now() + timeToAllowSendingCode })

const onSendSmsCodeDoMakeRequest: Epic<NAuthAction.ISendCodeBySmsPayload, typeof sendCodeBySms> = action$ =>
  action$.pipe(
    filter(sendCodeBySms.match),
    mergeMap(({ payload }) => {
      const { isSendingAgain, phone } = payload

      return request<ISendCodeBySmsResponse>({
        url: EAuthApiUrl.sendCode,
        method: 'POST',
        body: {
          phone,
        },
      }).pipe(
        mergeMap(response => {
          if (!isStatusOk(response)) return throwError(response)

          return [
            setSendingCodeStateActionCreator(isSendingAgain),
            sendingSmsRequestFinished({ isSendingAgain, tokenId: response.data.tokenId }),
          ]
        }),
        catchError(response => {
          if (response.meta.status === EShouldRegisterUserMetaStatus.invalidPhone) sendRegistrationWrongPhone()

          return [
            setSendingCodeStateActionCreator(isSendingAgain),
            sendingSmsRequestFailed({ shouldRegisterUser: response.meta.status === EShouldRegisterUserMetaStatus.notFound }),
          ]
        }),
      )
    }),
  )

const onRegistrationFormSubmittedDoSendRegistrationRequest: Epic<
  NAuthAction.ISendRegistrationRequestPayload,
  typeof sendRegistrationRequest
> = (action$, state$) =>
  action$.pipe(
    filter(sendRegistrationRequest.match),
    mergeMap(({ payload }) => {
      const { formValues, isSendingAgain } = payload
      const { fullName, phone, email, organizationProfile, receiveAdvertising = false, oauthUserId } = formValues
      const { organization } = organizationProfile

      return request<IRegistrationResponse>({
        url: EAuthApiUrl.changesUserData,
        method: 'POST',
        body: { fullName, phone, email, organizationProfile, receiveAdvertising, oauthUserId },
      }).pipe(
        mergeMap(response => {
          if (!isStatusOk(response)) return throwError(response)

          return [setSendingCodeStateActionCreator(isSendingAgain), registrationRequestSuccess({ tokenId: response.data.tokenId })]
        }),
        catchError(({ meta: { message } }) => {
          const errorMessageId = getErrorMessageId(message, organization.inn)
          const textId = `authModal.registration.failure.${errorMessageId}`

          sendRegistrationErrorEvent(`authModal.registration.failure.${errorMessageId}`)

          return [
            setSendingCodeStateActionCreator(isSendingAgain),
            registrationRequestFailed({ text: getMessage({ id: textId })(state$.value) }),
          ]
        }),
      )
    }),
  )

const onAuthImpersonationDoMakeRequest: Epic<NAuthAction.IIAuthImpersonationRequest, typeof authImpersonationRequest> = action$ =>
  action$.pipe(
    filter(authImpersonationRequest.match),
    mergeMap(({ payload }) =>
      request<ISubmitLoginResponse>({
        method: 'POST',
        url: EAuthApiUrl.impersonation,
        body: { phone: payload.phone },
      }).pipe(
        mergeMap(response => {
          if (!isStatusOk(response)) return throwError(response)

          const jwt = response.data.token
          AuthCookiesService.setJwt(jwt)

          return [setJwt({ jwt }), fetchUserProfile(), authImpersonationRequestFinished(), push('/profile')]
        }),
        catchError(response => of(authImpersonationRequestFailed({ status: response.meta.status }))),
      ),
    ),
  )

const onLogoutImpersonationUserDoSendRequest: Epic<undefined, typeof logoutImpersonationUser> = action$ =>
  action$.pipe(
    filter(logoutImpersonationUser.match),
    switchMap(() =>
      request<ISubmitLoginResponse>({
        method: 'POST',
        url: EAuthApiUrl.logout,
      }).pipe(
        mergeMap(({ data }) => {
          const jwt = data.token
          AuthCookiesService.setJwt(jwt)

          return [setJwt({ jwt }), fetchUserProfile(), push(ECommonRoute.impersonation)]
        }),
        catchError(() => of(clearSession())),
      ),
    ),
  )

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(
  onLogoutClickedDoClearSession,
  onSubmitLoginFormDoMakeAuthRequest,
  onRefreshJwtDoMakeRequest,
  onSendSmsCodeDoMakeRequest,
  onAppMountDoSetJwtToken,
  onRegistrationFormSubmittedDoSendRegistrationRequest,
  onAuthImpersonationDoMakeRequest,
  onLogoutImpersonationUserDoSendRequest,
  onClearSessionDoClearAuthCookies,
)
