import type { IntlShape } from 'react-intl'
import isInteger from 'lodash/isInteger'
import type { MonetaryAmountDTO } from '@/api/generated/tender'
import { EPriceCurrency, EPriceUom } from '@/types'

export const isNotRussianCurrency = (uom?: string): boolean => uom !== EPriceUom.RUB
export const getPriceUom = (uom: string): string => (isNotRussianCurrency(uom) ? 'у.e.' : EPriceCurrency.RUB)

export const formatMoney = (
  value: number | string | null | undefined,
  intl: IntlShape,
  maximumFractionDigits: number = 2,
  minimumFractionDigits: number = 2,
) => {
  if (value === null || value === undefined || isNaN(Number(value))) {
    return ''
  }

  return intl
    .formatNumber(Number(value), { maximumFractionDigits, ...(isInteger(Number(value).toFixed(2)) ? {} : { minimumFractionDigits }) })
    .replace(/,00$/, '')
}

export const totalPriceWithVat = (price: number, volume: number, vat: number) => {
  const priceWithVat = Math.round(price * vat * 100) / 100
  const totalPrice = Math.round(priceWithVat * volume * 100) / 100

  return totalPrice
}

export type TMonetaryAmountFormatted = {
  value: string
  uom: string
}
export function formatMonetaryAmount(amount: null | undefined, intl: IntlShape): null
// eslint-disable-next-line no-redeclare
export function formatMonetaryAmount(amount: MonetaryAmountDTO, intl: IntlShape): TMonetaryAmountFormatted
// eslint-disable-next-line no-redeclare
export function formatMonetaryAmount(amount: MonetaryAmountDTO | null | undefined, intl: IntlShape): TMonetaryAmountFormatted | null
// eslint-disable-next-line no-redeclare
export function formatMonetaryAmount(amount: MonetaryAmountDTO | null | undefined, intl: IntlShape): TMonetaryAmountFormatted | null {
  if (!amount || !intl) return null

  return {
    value: formatMoney(amount.value, intl),
    uom: getPriceUom(amount.uom),
  }
}
