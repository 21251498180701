// import { useQuery } from '@tanstack/react-query'
// import { StoreData } from '@/api/generated/base'
// import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
// import { getApi } from '@/logic/index/utils'
// import { IStoreLocation } from '@/types/IStore'

// export interface ICustomUserStore extends IStoreLocation {
//   id: string
// }

// const transformUserStoresResponse = (data?: StoreData[]) => ({
//   locations: data?.map(({ id, location }) => ({ ...location, id })),
//   userStores: data,
// })

// export const useUserStores = () => {
//   const { isAuthenticated } = useAuthenticateUser()

//   const { data, isLoading } = useQuery({
//     queryKey: ['userStore'],
//     queryFn: async () => {
//       const api = getApi()
//       const response = await api.getStores()
//       if (response.meta?.status !== 'OK') throw new Error('Ошибка получения данных')

//       return response.data
//     },
//     staleTime: Infinity,
//     enabled: isAuthenticated,
//     select: transformUserStoresResponse,
//   })

//   return {
//     isLocationsLoading: isLoading,
//     locations: (data?.locations || []) as IStoreLocation[],
//     userStores: data?.userStores || [],
//   }
// }

import { useMemo } from 'react'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { useData } from '@/logic/data'
import type { IUseDataOptions } from '@/logic/data/interfaces'
import type { IStore, IStoreLocation } from '@/types/IStore'

const defaultUserStore: IStore[] = []

export interface ICustomUserStore extends IStoreLocation {
  id: string
}

export const useUserStores = (options: IUseDataOptions = {}) => {
  const { isAuthenticated } = useAuthenticateUser()

  const { data: userStores = defaultUserStore, isLoading: isLocationsLoading } = useData<IStore[]>('stores', {
    ...options,
    shouldNotLoadDataOnMount: !isAuthenticated,
  })
  const locations: ICustomUserStore[] = useMemo(() => userStores.map(({ id, location }) => ({ ...location, id })), [userStores])

  return {
    isLocationsLoading,
    locations,
    userStores,
  }
}
