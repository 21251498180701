import type { FC } from 'react'
import React from 'react'
import type { FaqCategoryData } from '@/api/generated/base'
import { Loader, MetaInfo, PageNoticeText } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import { useGetFaqEntity } from '@/pages/QuestionsAndAnswers/hooks'
import { FaqBreadcrumbs } from '..'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const FaqCategory: FC = () => {
  const { data: category, isLoading } = useGetFaqEntity<FaqCategoryData>('faqCategory', 'categoryId')
  const { name, description } = category || {}

  return (
    <section className="background_white faq-category space-holder-top40-only-md">
      <FaqBreadcrumbs category={category} />
      {category ? (
        <>
          <h3 className="space-holder16">{name}</h3>
          <p>{description}</p>
        </>
      ) : (
        <PageNoticeText textId="faqCategory.chooseCategory" />
      )}
      <MetaInfo metaInfo={{ title: name, description }} />
      <Loader isVisible={isLoading} />
    </section>
  )
}

export default FaqCategory
