import type { ShortFaqArticleData } from '@/api/generated/base'
import type { IFormattedGtmEventPayload } from '@/logic/metrika/interfaces'

export enum EFaqRouteUrl {
  QUESTION = '/faq/categories/:categoryId/specialization/:specializationId/question/:questionId',
  SPECIALIZATION = '/faq/categories/:categoryId/specialization/:specializationId?',
  CATEGORY = '/faq/categories/:categoryId?',
  CATEGORY_DETAILS = '/faq/categories/:categoryId/details',
  CATEGORIES = '/faq/categories',
  AUTHOR = '/faq/authors/:authorId',
  SEARCH = '/faq/search',
}

export type TFaqQuestions = Record<string, Readonly<ShortFaqArticleData[]>>

export interface IFaqParams {
  categoryId?: string
  questionId?: string
  specializationId?: string
  authorId?: string
}

export interface IFaqContext {
  params: IFaqParams
}

type TGtmContextPrefix = 'ttl' | 'txt' | 'nm' | 'target' | 'nmb'

interface IGtmContext {
  name: string
  prefix?: TGtmContextPrefix
}

export interface ITransliteratedGtmEventsOptions {
  blockName: string
  context?: IGtmContext
  isCategory?: boolean
  eventLabel?: string
}

export type TGetTransliteratedGtmEvents = (options: ITransliteratedGtmEventsOptions) => IFormattedGtmEventPayload

export interface IFaqGtmEventHandlers {
  pushFocusEventToGtm: () => void
  pushChangeEventToGtm: () => void
}

export interface IGetSearchResultsEventPayloadParams {
  resultsAmount: number
  keyword: string
  isSuccess: boolean
}

export type TIGetSearchResultsEventPayload = (params: IGetSearchResultsEventPayloadParams) => IFormattedGtmEventPayload
