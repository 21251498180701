import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import axiosClient from '@/api/client/axios'
import { openModal } from '@/components/composed/Modal/actions'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { SignDocumentWithUkepModal, SignOfferWithUkepModal } from '@/components/composed/modals'
import { EDocumentType } from '@/components/composed/modals/DealAgreementModal/interfaces'
import { confirmOnCloseSignDocumentsWithUkep } from '@/components/composed/modals/SignDocumentsAccreditationWithUkepModal/helpers'
import type { ISignDocumentWithUkepModalOptions } from '@/components/composed/modals/SignDocumentWithUkepModal/types'
import type { IButtonProps } from '@/components/ui/Button/interfaces'
import { SaleRequestApiName } from '@/constants/outputs'
import { createAccreditation } from '@/logic/accreditation/actions'
import { isImpersonationUser, isUserAgrarian, isUserSupplier } from '@/logic/auth/reducer'
import { loadData } from '@/logic/data/actions'
import { useGtmEventWithUserInfo } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmAction } from '@/logic/metrika/financing/types'
import { getAgrarianOrder } from '@/logic/order/reducer'
import { EProfileRequestType } from '@/logic/profile/constants'
import { ESignType } from '@/logic/signs/selectSignType/types'
import { scrollToDetails } from '@/pages/Profiles/UserProfile/components/AgrarianSale/helpers'
import type { RootState } from '@/redux/interfaces'
import { useLocation, useParams, useRouterApi } from '@/router/hooks'
import { ECommonRoute } from '@/types'
import { EAccreditationType } from '@/types/Accreditation'
import { EPlatformType } from '@/types/EPlatformType'
import type { ESaleRequestType } from '@/types/ESaleRequestType'
import type { IHarvestSaleRequestStatusActions } from '@/types/HarvestSaleRequest'
import { EAgrarianOrderStatus } from '@/types/Order'
import { ESupplierSignType } from '@/types/Order/IAgrarianOrder'
import { downloadBlobFile } from '@/utils/files'
import { getPlatformType } from '@/utils/getPlatformType'
import type { IStatusModelActionsProps } from './interfaces'
import { messages } from './messages'

addModalToWellKnown({ SignOfferWithUkepModal, SignDocumentWithUkepModal })

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  isSupplier: isUserSupplier()(state),
  isImpersonated: isImpersonationUser()(state),
  order: getAgrarianOrder()(state),
})

export function useStatusModelButtons(config: Omit<IStatusModelActionsProps, 'isOrderClosed'>) {
  const dispatch = useDispatch()
  const history = useRouterApi()
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const { pushGtmEventWithUserInfo } = useGtmEventWithUserInfo()
  const { origin } = useLocation()
  const { saleRequestType } = useParams<{ saleRequestType?: ESaleRequestType }>()
  const { isAgrarian, isSupplier, isImpersonated, order } = useSelector(mapState)
  const isImpersonatedSupplier = isImpersonated && isSupplier

  const {
    allowToEditOrder,
    shouldEditOrder,
    openRejectingOrderModal,
    changeOrderStatus,
    payOrderWithSberBusiness,
    openFeedback,
    openSupplies,
    openSupplyFormModal,
    status,
    entityType,
    id,
    version,
    organizationId,
    accreditationType,
    isConfirmationButtonDisabled = false,
    isOrderStatusChangingInProcess,
    paymentType,
  } = config

  const { mutateAsync } = useMutation({
    // @ts-expect-error
    mutationKey: [status?.actions?.download?.documentUrl],
    mutationFn: () => {
      if (!status?.actions?.download?.documentUrl) return null

      return axiosClient<Blob>({
        method: 'get',
        url: status.actions.download.documentUrl,
        params: { attachment: true },
        responseType: 'blob',
      }).then(res => res.data)
    },
  })

  const onDownload = useCallback(async () => {
    const timeout = setTimeout(() => {
      setIsLoadingButton(true)
    }, 300)

    try {
      const data = await mutateAsync()

      if (data) {
        const file = data as Blob
        const blob = new Blob([file], { type: file.type })
        downloadBlobFile(blob, messages.downloadCheck)
      }
    } catch (error) {
      console.warn(error)
    } finally {
      clearTimeout(timeout)
      setIsLoadingButton(false)
    }
  }, [mutateAsync])
  const { actions, params, id: statusId } = status || {}
  const { needAccreditation } = params || {}
  const shouldGetAccreditation = !!needAccreditation && isAgrarian

  const onSignCryptoClick = useCallback(() => {
    if (entityType === EProfileRequestType.ORDERS) {
      dispatch(openModal({ options: { dialogId: 'SignOfferWithUkepModal' } }))
    } else {
      dispatch(
        openModal({
          options: {
            dialogId: 'SignDocumentWithUkepModal',
            onConfirm: () => confirmOnCloseSignDocumentsWithUkep(dispatch),
          },
          contentOptions: {
            documentId: id,
            documentType: (actions as IHarvestSaleRequestStatusActions)?.signCrypto?.documentType,
            version,
            isDraggable: true,
            onSuccessAction: () => {
              if (saleRequestType) {
                dispatch(
                  loadData({
                    name: SaleRequestApiName[saleRequestType],
                    params: { id },
                    shouldLoadForcibly: true,
                  }),
                )
              }
            },
          } as ISignDocumentWithUkepModalOptions,
        }),
      )
    }
  }, [entityType, dispatch, id, actions, version, saleRequestType])

  const onPayOnlineClick = useCallback(() => {
    pushGtmEventWithUserInfo({
      event: 'userEvent',
      pagePart: 'content',
      blockName: 'orderDetailsSeller',
      eventAction: EGtmAction.CLICK,
      eventLabel: 'onlinePayment',
      eventStatus: 'success',
      eventEcommerce: null,
      firingOptions: 'oncePerEvent',
      ecommerce: null,
      userSegment: null,
    })

    history.push({ pathname: ECommonRoute.payOnline })
  }, [history, pushGtmEventWithUserInfo])

  const cancel = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.cancel,
      children: actions?.cancel?.name,
      onClick: openRejectingOrderModal,
    }),
    [actions?.cancel, openRejectingOrderModal],
  )

  const feedback = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.feedback,
      children: actions?.feedback?.name,
      onClick: openFeedback || (() => window.open(origin + ECommonRoute.feedback)),
    }),
    [actions?.feedback, openFeedback, origin],
  )

  const sberPay = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.sberPay,
      children: actions?.sberPay?.name,
      onClick: payOrderWithSberBusiness,
    }),
    [actions?.sberPay, payOrderWithSberBusiness],
  )

  const edit = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.edit,
      isDisabled: shouldEditOrder,
      children: actions?.edit?.name,
      onClick: () => {
        allowToEditOrder()
        scrollToDetails()
      },
    }),
    [actions?.edit, shouldEditOrder, allowToEditOrder],
  )

  const download = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.download,
      children: actions?.download?.name,
      loaderProps: { isVisible: isLoadingButton },
      onClick: onDownload,
    }),
    [actions?.download, isLoadingButton, onDownload],
  )

  const supplySchedule = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.supplySchedule,
      children: actions?.supplySchedule?.name,
      onClick: openSupplies,
    }),
    [actions?.supplySchedule, openSupplies],
  )

  const addSupply = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.addSupply,
      children: actions?.addSupply?.name,
      onClick: openSupplyFormModal,
    }),
    [actions?.addSupply, openSupplyFormModal],
  )

  // кнопка `confirm` нужна, если:
  // - пришёл соответствующий экшен от бэка, но при этом:
  //  - для агрария не требуется аккредитация;
  //  - заказ не в статусе `ACCREDITATION` и текущий юзер - не админ под поставщиком
  const isConfirmShown =
    actions?.confirm && !shouldGetAccreditation && !(isImpersonatedSupplier && status?.id === EAgrarianOrderStatus.ACCREDITATION)

  const confirm = useMemo(
    (): IButtonProps => ({
      isHidden: !isConfirmShown,
      isDisabled: shouldEditOrder || isConfirmationButtonDisabled,
      loaderProps: { isVisible: isOrderStatusChangingInProcess },
      children:
        status?.id === EAgrarianOrderStatus.SUPPLIER_PROPOSAL && order?.supplier.signMethod === ESupplierSignType.ANY
          ? messages.orderActions.selectTypeSign.button
          : actions?.confirm?.name,
      onClick: changeOrderStatus,
    }),
    [
      actions?.confirm,
      isConfirmShown,
      shouldEditOrder,
      isConfirmationButtonDisabled,
      isOrderStatusChangingInProcess,
      status?.id,
      order?.supplier.signMethod,
      changeOrderStatus,
    ],
  )

  const sign = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.sign || order?.signMethod === ESignType.UKEP,
      isDisabled: shouldEditOrder,
      children: actions?.sign?.name,
      onClick: () => {
        dispatch(
          openModal({
            options: {
              dialogId: 'DealAgreementModal',
              isDraggable: true,
            },
            contentOptions: {
              entityType,
              id,
              version,
              paymentType,
              orderStatusId: statusId,
              documentType: statusId === EAgrarianOrderStatus.AGRARIAN_ASSIGNMENT_WAITING ? EDocumentType.ASSIGNMENT : EDocumentType.OFFER,
            },
          }),
        )
      },
    }),
    [actions?.sign, order?.signMethod, shouldEditOrder, dispatch, entityType, id, version, paymentType, statusId],
  )

  const signCrypto = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.signCrypto || order?.signMethod === ESignType.PEP,
      children: actions?.signCrypto?.name,
      onClick: onSignCryptoClick,
    }),
    [actions?.signCrypto, onSignCryptoClick, order?.signMethod],
  )

  const accreditation = useMemo(
    (): IButtonProps => ({
      isHidden: !shouldGetAccreditation,
      textId:
        accreditationType === EAccreditationType.REQUEST
          ? messages.requestActions.getAccreditation.button
          : messages.orderActions.getAccreditation.button,
      onClick: () => {
        if (organizationId) dispatch(createAccreditation({ organizationId, accreditationType }))
      },
    }),
    [shouldGetAccreditation, dispatch, organizationId, accreditationType],
  )

  const accreditationWithUKEP = useMemo(
    (): IButtonProps => ({
      isHidden: !actions?.addDocumentUKEP || getPlatformType() !== EPlatformType.DESKTOP,
      children: messages.orderActions.getAccreditationWithUKEP.button,
      onClick: () =>
        dispatch(
          openModal({
            options: {
              dialogId: 'SignDocumentsAccreditationWithUkepModal',
              onConfirm: () => confirmOnCloseSignDocumentsWithUkep(dispatch),
            },
          }),
        ),
    }),
    [dispatch, actions?.addDocumentUKEP],
  )

  const payOnline = useMemo(
    (): IButtonProps => ({
      isHidden: statusId !== EAgrarianOrderStatus.PREPAYMENT_WAITING || !isAgrarian,
      children: messages.orderActions.payOnline.button,
      onClick: onPayOnlineClick,
    }),
    [isAgrarian, onPayOnlineClick, statusId],
  )

  return {
    cancel,
    feedback,
    sberPay,
    edit,
    download,
    supplySchedule,
    addSupply,
    confirm,
    sign,
    accreditation,
    accreditationWithUKEP,
    signCrypto,
    payOnline,
  }
}
