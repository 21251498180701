import type { FindForVitrinaMutationRequest, FindForVitrinaQueryParams } from '@/api/kubik/auctions'
import { AuctionStatus, findForVitrina } from '@/api/kubik/auctions'
import type { TSsrPrefetchQueries } from '@/interfaces'

export const getVitrinaQueryKey = (params?: FindForVitrinaQueryParams) =>
  [{ url: `/v1/auctions/view_for-vitrina` }, ...(params ? [params] : [])] as const

export const betsVitrinaPrefetchSsrQueries: TSsrPrefetchQueries = (routeParams: Record<string, string | undefined>) => {
  const data: FindForVitrinaMutationRequest = {
    status: routeParams?.status ? [routeParams?.status as AuctionStatus] : [AuctionStatus.planned],
  }

  const params: FindForVitrinaQueryParams = {
    offset: routeParams?.offset ? Number(routeParams?.offset) : 0,
    limit: routeParams?.limit ? Number(routeParams?.limit) : 10,
  }

  const keyParams = {
    ...data,
    ...params,
  }

  return [{ queryFn: () => findForVitrina(data, params), queryKey: getVitrinaQueryKey(keyParams) }]
}
