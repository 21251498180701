export enum EFinancingOfferType {
  SBERPAY = 'SBERPAY',
  BNPL_AGROINTEGRATOR = 'BNPL_AGROINTEGRATOR',
  LEASING_RAL = 'LEASING_RAL',
  LEASING_VTB = 'LEASING_VTB',
  LEASING_INTERLEASING = 'LEASING_INTERLEASING',
  LEASING_BALTIYSKIY = 'LEASING_BALTIYSKIY',
  LEASING_ALFA = 'LEASING_ALFA',
  LEASING_STONE_XXI = 'LEASING_STONE_XXI',
  REVOLVING_LOAN = 'REVOLVING_LOAN',
  REVOLVING_LOAN_SBER = 'SBER_OBOROTKA_LOAN',
  ADVANCE_PAYMENT = 'ADVANCE_PAYMENT',
  CREDIT = 'CREDIT',
  INVESTMENT = 'INVESTMENT',
  INDIVIDUAL_OFFER = 'INDIVIDUAL_OFFER',
  AGROCONSULTING = 'AGROCONSULTING',
  CREDIT_TINKOFF = 'CREDIT_TINKOFF',
  ALFA_EXPRESS_LOAN = 'ALFA_EXPRESS_LOAN',
  EXPRESS_MFO = 'EXPRESS_MFO',
  AGROINTEGRATOR = 'AGROINTEGRATOR',
  MONEY_KAPITAL = 'MONEY_KAPITAL',
  SBER_LEASING = 'SBER_LEASING',
  SBER_INVESTMENT_LOAN = 'SBER_INVESTMENT_LOAN',
  CENTER_INVEST_LOAN = 'CENTER_INVEST_BANK',
  MARKETPLACE = 'MARKETPLACE',
  LOMBARD_LOAN_ALFA_BANK = 'LOMBARD_LOAN_ALFA_BANK',
  LEASING_FLEET_FINANCE = 'LEASING_FLEET_FINANCE',
  BNPL_MANDARIN = 'BNPL_MANDARIN',
}
