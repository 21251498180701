import {
  AuthModal,
  ChangeActiveOrganisationModal,
  ChangeRoleModal,
  ChoosingLocationModal,
  CustomizedFormModal,
  DealAgreementModal,
  FailureOrderModal,
  InfoModal,
  MessageModal,
  OrganizationNoticeModal,
  SuccessfulRegistrationModal,
  SuccessOrderModal,
} from '../modals'
import type { IKnownModals } from './interfaces'

export const KnownModals: IKnownModals = {
  AuthModal,
  ChangeActiveOrganisationModal,
  SuccessOrderModal,
  FailureOrderModal,
  InfoModal,
  MessageModal,
  CustomizedFormModal,
  DealAgreementModal,
  ChoosingLocationModal,
  OrganizationNoticeModal,
  SuccessfulRegistrationModal,
  ChangeRoleModal,
}

export const addModalToWellKnown = (modals: IKnownModals): void => {
  Object.keys(modals).forEach(modalName => {
    // assertion is used here because TS can't pick up  that KnownModals[modalName] was just checked
    if (!KnownModals[modalName]) KnownModals[modalName] = modals[modalName] as IKnownModals[string]
    // eslint-disable-next-line no-console
    else console.error(`Диалог с именем ${modalName} уже добавлен в список общеизвестных диалогов`)
  })
}
