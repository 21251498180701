import type { TMethod } from '@/api/interfaces'
import { requestData } from '@/api/request'
import { EHarvestSaleLotsApiUrl } from '@/api/urls'
import type { ICreateHarvestSaleRequestData } from '@/logic/harvestSaleRequest/interfaces'
import type { IAgrarianSaleChangingRequestBody } from '@/logic/profile/interface'
import type { TCreateHarvestSaleResponse } from '@/services/HarvestSaleRequestService/interfaces'
import type { IHarvestSaleRequest } from '@/types/HarvestSaleRequest'
import type { TEntityId } from '@/types/TEntityId'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'

export interface IChangeHarvestSaleRequestStatus {
  requestId: TEntityId
  version: string
  statusComment?: string
}

export interface IChangeHarvestSaleRequest {
  changedValues: IAgrarianSaleChangingRequestBody
  requestId: TEntityId
  version: string
  statusComment?: string
}

export interface IDeleteHarvestSaleRequestPayload {
  version: string
  requestId: TEntityId
  statusComment?: string
}

/** Сервис работы с заявкой на продажу зерна outputs */
export default class HarvestSaleRequestService {
  private createRequestAbortController: AbortController | null = null
  private deleteRequestAbortController: AbortController | null = null

  /**
   * Создание закаща
   * @param {Object} parameters - информация для создания заявки
   */
  createHarvestSaleRequest = (parameters: ICreateHarvestSaleRequestData) => {
    if (this.createRequestAbortController) {
      this.createRequestAbortController.abort()
    }

    this.createRequestAbortController = new AbortController()

    const options = {
      url: createRouterLink(EHarvestSaleLotsApiUrl.saleRequest, {}),
      method: 'POST' as TMethod,
      body: parameters,
      signal: this.createRequestAbortController.signal,
    }

    return requestData<TCreateHarvestSaleResponse>(options).then(response => {
      this.createRequestAbortController = null

      return response
    })
  }

  /**
   * Изменение статуса заявки
   * @param {Object} options
   * @prop {number} options.orderId - идентификатор заявки
   * @prop {string} options.version - версия заявки, updateDate
   */
  changeHarvestSaleRequestStatus = ({ requestId, version, statusComment }: IChangeHarvestSaleRequestStatus) =>
    requestData<IHarvestSaleRequest>({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleLotsApiUrl.saleRequestNextStatus, { requestId }), { version, statusComment }),
      method: 'POST',
    })

  /**
   * Изменение данных заявки
   * @param {Object} options
   * @prop {number} options.requestId - идентификатор заявки
   * @prop {string} options.version - версия заявки, updateDate
   * @prop {string} options.statusComment - комментарий
   * @prop {IAgrarianSaleChangingRequestBody} options.changedValues - изменяемые значения
   */
  changeHarvestSaleRequest = ({ requestId, version, statusComment, changedValues }: IChangeHarvestSaleRequest) =>
    requestData<IHarvestSaleRequest>({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleLotsApiUrl.saleRequestWithId, { requestId }), { version, statusComment }),
      method: 'PATCH',
      body: changedValues,
    })

  /**
   * Удаление заявки
   * @param {Object} options
   * @prop {number} options.requestId - идентификатор заявки
   * @prop {string} options.version - версия заявки, updateDate
   * @prop {string} options.statusComment - комментарий
   */
  deleteHarvestSaleRequest = ({ requestId, version, statusComment }: IDeleteHarvestSaleRequestPayload) => {
    if (this.deleteRequestAbortController) {
      this.deleteRequestAbortController.abort()
    }

    this.deleteRequestAbortController = new AbortController()

    return requestData<IHarvestSaleRequest>({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleLotsApiUrl.saleRequestWithId, { requestId }), { version, statusComment }),
      method: 'DELETE',
      signal: this.deleteRequestAbortController.signal,
    }).then(response => {
      this.deleteRequestAbortController = null

      return response
    })
  }

  /**
   * Создания лида на продажу урожая
   * @param {Object} parameters - данные для создания лида
   */
  createHarvestSaleLead = (parameters: ICreateHarvestSaleRequestData) =>
    requestData({
      url: createRouterLink(EHarvestSaleLotsApiUrl.expansionRequest, {}),
      method: 'POST',
      body: parameters,
    })
}
