import type { TMessages } from '@/types/TMessages'

export const messages = {
  orderActions: {
    getAccreditation: {
      button: 'Заполнить вручную',
    },
    getAccreditationWithUKEP: {
      button: 'Заполнить и подписать с УКЭП',
    },
    selectTypeSign: {
      button: 'Согласиться и выбрать подпись',
    },
    payOnline: {
      button: 'Оплатить онлайн',
    },
  },
  requestActions: {
    getAccreditation: {
      button: 'Перейти к загрузке документов',
    },
  },
  downloadCheck: 'Счет на оплату',
} satisfies TMessages
