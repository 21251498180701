import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { getRequestData } from '@/logic/data/reducer'
import { getInitialState } from '@/redux/helpers'
import type { RootState } from '@/redux/interfaces'
import type { IRegion } from '@/types'
import { INITIAL_USER_REGION_ID } from './constants'
import type { IUserRegionState } from './types'
import { UserRegionStorage } from './UserRegionStorage'

const initialState: IUserRegionState = {
  region: {
    code: INITIAL_USER_REGION_ID,
    name: '', // лейбл установится на актуальный во время ssr
  },
  isConfirmed: false,
}

const reducerName = 'userRegion'

const userRegionSlice = createSlice({
  name: reducerName,
  initialState: getInitialState(reducerName, initialState),
  reducers: {
    setUserRegion(state, { payload: region }: PayloadAction<IUserRegionState['region']>) {
      state.region = region
      UserRegionStorage.set(region.code)
    },
    setIsUserRegionConfirmed(state) {
      state.isConfirmed = true
      UserRegionStorage.confirm()
    },
  },
})

export const { setUserRegion, setIsUserRegionConfirmed } = userRegionSlice.actions

export const getUserRegion = () => (state: RootState) => state[reducerName].region
export const getIsUserRegionConfirmed = () => (state: RootState) => state[reducerName].isConfirmed

export const getRegions = createSelector(
  (state: RootState) => getRequestData<IRegion[]>('regions')(state),
  requestData => requestData?.data ?? null,
)

export const getRegionById = createSelector(
  getRegions,
  (_state: RootState, regionId: Maybe<number>) => regionId,
  (regions, regionId) => (regionId ? (regions?.find(region => region.code === regionId) ?? null) : null),
)

export default userRegionSlice.reducer
