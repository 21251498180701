import type { OrganizationData } from '@/api/generated/base'
import type { IStore } from '@/types/IStore'
import { openModal } from '../Modal/actions'
import type { IOrganizationNoticeModalOptions } from '../modals/OrganizationNoticeModal/interfaces'

export const openSupplierNoticeModal = (store: IStore | undefined, supplier: OrganizationData) => {
  const { location } = store || {}
  const { address: storeAddress, latitude, longitude } = location || {}
  const { address: supplierAddress, description: supplierDescription, inn, name: supplierName, ogrn } = supplier

  return openModal({
    options: {
      dialogId: 'OrganizationNoticeModal',
    },
    contentOptions: {
      title: supplierName,
      storeCoordinates: [latitude, longitude],
      storeAddress,
      requisites: {
        ogrn,
        inn,
        address: supplierAddress,
      },
      description: supplierDescription,
      modalContentClassName: 'organization-notice-modal-container',
    } as IOrganizationNoticeModalOptions,
  })
}
