import type { FormApi } from 'final-form'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import reduce from 'lodash/reduce'
import URI from 'urijs'
import type { IResponse } from '@/api/interfaces'
import { history } from '@/browserHistory'
import { ETagCreationType } from '@/logic/harvestSale/constants'
import type { IFetchTemplatesPayload, NHarvestSaleAction } from '@/logic/harvestSale/interfaces'
import type { ICreateHarvestSaleRequestData } from '@/logic/harvestSaleRequest/interfaces'
import type { ICreateHarvestSaleDraft } from '@/services/DraftService/interfaces'
import type { ITemplate } from '@/types/HarvestSaleLots'
import { EOrderType } from '@/types/HarvestSaleLots'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { formatDate } from '@/utils/formatUtils'
import type { ITemplateRequestData, THarvestSaleFormValues } from './interfaces'

export const getMinimumCustomValue = (values: number[]) => (values[values.length - 1] as number) + 1

export const fixProteinValue = (value: string | number) => String(Number(value).toFixed(1)).replace('.', ',')

export const lotsRegionCodesStorageKey = 'lotsRegionCodes'
export const LotsRegionCodesStorage = {
  set(regionCodes: number[]) {
    window.localStorage.setItem(lotsRegionCodesStorageKey, JSON.stringify(regionCodes))
  },

  get(): number[] | null {
    if (ExecutionEnvironment.isSsr) return null

    const regionCodes = window.localStorage.getItem(lotsRegionCodesStorageKey)

    return regionCodes && JSON.parse(regionCodes)
  },

  clear() {
    window.localStorage.removeItem(lotsRegionCodesStorageKey)
  },
}

const excludedFormValuesOfCreatingHarvestSaleRequest = [
  'volume',
  'desiredPrice',
  'testRecordFile',
  'quality',
  'deliveryOptions',
  'orderType',
] as const

const excludedFormValuesOfCreatingHarvestSaleRequestDraft = [
  'volume',
  'desiredPrice',
  'testRecordFile',
  'quality',
  'deliveryOptions',
  'orderType',
  'buyerId',
  'agentId',
] as const

export const transformPayloadStoreId = ({
  payload,
  storeId,
}: {
  payload: NHarvestSaleAction.IRequestHarvestSalePayload
  storeId?: string
}): NHarvestSaleAction.IRequestHarvestSalePayload => ({
  ...payload,
  formValues: {
    ...payload.formValues,
    deliveryOptions: { ...payload.formValues.deliveryOptions, storeId: storeId || payload.formValues.deliveryOptions.storeId },
  },
})
export const getCreatingHarvestRequestBody = (payload: NHarvestSaleAction.IRequestHarvestSalePayload): ICreateHarvestSaleRequestData => {
  const { formValues, template, harvest } = payload
  const { volume, desiredPrice, quality, deliveryOptions, orderType } = formValues
  const { storeId, departureDate, deliveryBasisId, elevatorId } = deliveryOptions
  const [startDate, endDate] = departureDate

  const requestBody: ICreateHarvestSaleRequestData = {
    ...omit(formValues, excludedFormValuesOfCreatingHarvestSaleRequest),
    quality,
    deliveryOptions: {
      storeId,
      startDate: formatDate(startDate, 'yyyy-MM-dd'),
      endDate: formatDate(endDate, 'yyyy-MM-dd'),
      deliveryBasisId,
      elevatorId,
    },
    volume: Number(volume),
    price: {
      uom: template?.price?.uom || template?.fca?.price?.uom || template?.exw?.uom || template?.fob?.uom || 'RUB',
      value: Number(desiredPrice),
    },
    isMultiRequest: orderType === EOrderType.multiple,
    tagCreation: template && ETagCreationType.purchase,
  }

  if (harvest) requestBody.cultureId = harvest.culture.id
  else requestBody.templateId = template?.id

  return requestBody
}

/** Функция получает данные из черновика для заполнения формы
 * (При формировании requestBody исключаются данные, загрузка которых на форму не требуется)
 */
export const getCreatingDraftHarvestRequestBody = (payload: NHarvestSaleAction.IRequestHarvestSalePayload): ICreateHarvestSaleDraft => {
  const { formValues } = payload
  const { quality, deliveryOptions } = formValues

  let requestBody: ICreateHarvestSaleDraft = {
    ...omit(formValues, excludedFormValuesOfCreatingHarvestSaleRequestDraft),
    quality,
  }

  if (!isEmpty(deliveryOptions)) {
    const { storeId, deliveryBasisId } = deliveryOptions || {}

    requestBody = {
      ...requestBody,
      deliveryOptions: {
        storeId,
        deliveryBasisId,
      },
    }
  }

  return requestBody
}

const getArrayOfSelectedValue = (obj: Record<string, boolean>) =>
  Object.entries(obj).reduce((acc: string[], [key, isSelected]) => (isSelected ? [...acc, key] : acc), [])

export const getTemplateRequestBody = (payload: IFetchTemplatesPayload): ITemplateRequestData => {
  const { params, filterValues = {}, indicators = [], pageNumber } = payload
  const { regionCodes, elevators: elevatorsMap = {}, buyers: buyersMap = {}, proteinFrom } = filterValues
  const cultureId = params?.cultureId || filterValues?.cultureId
  const elevators = getArrayOfSelectedValue(elevatorsMap)
  const buyers = getArrayOfSelectedValue(buyersMap)

  const body: ITemplateRequestData = {
    indicatorsData: {
      indicators: indicators.reduce(
        (acc, { name, value }) => (filterValues[value]?.length ? { ...acc, [value]: { name, value: filterValues[value] } } : acc),
        {},
      ),
    },
  }

  if (cultureId) body.cultures = [cultureId]
  if (regionCodes?.length) body.regionCodes = typeof regionCodes === 'string' ? [Number(regionCodes)] : regionCodes.map(Number)
  if (elevators.length) body.elevators = elevators
  if (buyers.length) body.buyers = buyers
  if (proteinFrom?.length) body.proteinFrom = proteinFrom
  if (pageNumber) body.pageNumber = pageNumber

  return body
}

export const isErrorMultipleOrderWithBadRegion = <T>(response?: IResponse<T>) =>
  response?.meta.status === 'ERROR' && response?.meta.message?.includes('region')

export const getCheckboxValuesFromQuery = (values: string | string[] | undefined): Record<string, boolean> | undefined => {
  if (typeof values === 'string') return { [values]: true }
  else if (Array.isArray(values)) return values.reduce((result, key) => ({ ...result, [key]: true }), {})

  return undefined
}

const prepareCheckboxesForQuery = (values: Record<string, boolean>): string[] | undefined => {
  if (!values) return undefined

  return reduce(values, (result: string[], isChecked, id) => (isChecked ? [...result, id] : result), [])
}

export const deleteRegionCodesInSearch = () => {
  const { pathname, hash } = window.location

  history.push(new URI(`${pathname}${hash}`).removeQuery('regionCodes').toString())
}

export const saveFilterValuesToQuery = (filterValues: THarvestSaleFormValues) => {
  const { pathname, hash } = window.location
  const { elevators, buyers } = filterValues

  history.replace(
    new URI(`${pathname}${hash}`)
      .addQuery({
        ...omit(filterValues, ['cultureId', 'buyers', 'elevators']),
        buyers: prepareCheckboxesForQuery(buyers),
        elevators: prepareCheckboxesForQuery(elevators),
      })
      .toString(),
  )
}

export const autocompleteDesiredPrice = (deliveryBasisId: string, template: ITemplate | undefined, form: FormApi) => {
  let desiredPrice: number | undefined

  switch (deliveryBasisId) {
    case 'pickup':
      desiredPrice = template?.fca?.price?.value
      break
    case 'exw':
      desiredPrice = template?.exw?.value
      break
    case 'fob':
      desiredPrice = template?.fob?.value
      break
    default:
      desiredPrice = template?.price?.value
      break
  }

  form?.change('desiredPrice', String(desiredPrice || ''))
}
